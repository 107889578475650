<template>
  <div class="social-links" v-if="false">
    <a href="#" target="_blank" class="social-links__item">
      <svg class="social-links__ico">
        <use href="#fb"/>
      </svg>
      <svg class="social-links__lines">
        <use href="#lines"></use>
      </svg>
    </a>
    <a href="#" target="_blank" class="social-links__item">
      <svg class="social-links__ico">
        <use href="#linkedin"/>
      </svg>
      <svg class="social-links__lines">
        <use href="#lines"></use>
      </svg>
    </a>
    <a href="#" target="_blank" class="social-links__item">
      <svg class="social-links__ico">
        <use href="#twitter"/>
      </svg>
      <svg class="social-links__lines">
        <use href="#lines"></use>
      </svg>
    </a>
  </div>
</template>

<style scoped>

  .social-links {
    margin: 0 -12px;
  }

  .social-links__item {
    position: relative;
    display: inline-block;
    padding: 0 12px;

    + .social-links__item {
      &:after {
        content: '';
        top: 50%;
        transform: translateY(-50%) translateX(4px);
        left: -12px;
        position: absolute;
        width: 8px;
        height: 1px;
        background-color: #284394;
      }
    }

    &:hover {
      .social-links__lines {
        display: block;
      }
    }
  }

  .social-links__ico {
    width: 32px;
    height: 40px;
  }

  .social-links__lines {
    display: none;
    position: absolute;
    width: 9px;
    height: 11px;
    transform: rotate(-90deg);
    top: -8px;
    right: 8px;
    fill: #264096;
    transition: .3s ease;
  }

</style>
